import { graphql } from 'gatsby'
import React from 'react'
import RevisionesContent from '../../../../src/common/components/revisiones/Revisiones'

const Revisiones = ({ data }) => <RevisionesContent data={data} />
export default Revisiones

export const pageQuery = graphql`
  query revisionesQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }

    banners_ca_desktop: allS3Asset(
      filter: { Key: { glob: "cambio-aceite/banners/ca/desktop/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_mobile: allS3Asset(
      filter: { Key: { glob: "cambio-aceite/banners/ca/mobile/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_desktop: allS3Asset(
      filter: { Key: { glob: "cambio-aceite/banners/es/desktop/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_mobile: allS3Asset(
      filter: { Key: { glob: "cambio-aceite/banners/es/mobile/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`
